import React, { HTMLAttributes } from 'react';
import { Container } from 'react-bootstrap';

import cx from 'classnames';

export interface ISectionWithContainerProps
  extends HTMLAttributes<HTMLElement> {
  containerProps?: any;
  className?: string;
  style?: any;
  role?: string;
  noPadding?: boolean;
}
const SectionWithContainer: React.FC<ISectionWithContainerProps> = ({
  className,
  containerProps,
  noPadding,
  children,
  ...props
}) => {
  return (
    <section className={cx(!noPadding && 'py-4 py-lg-5', className)} {...props}>
      <Container {...containerProps}>{children}</Container>
    </section>
  );
};

export default SectionWithContainer;
