import React, { HTMLAttributes } from 'react';
import { Form, FormCheckProps } from 'react-bootstrap';

import cx from 'classnames';

interface FormRadioButtonGroupProps extends HTMLAttributes<HTMLElement> {
  options: any[];
  value?: any;
  onChange?: (value: any) => any;
  getLabelValue?: (value: any) => string;
  optionValueName?: string;
  optionLabelName?: string;
  formCheckProps?: FormCheckProps;
  labelProps?: any;
  inline?: boolean;
}
const FormRadioButtonGroup: React.FC<FormRadioButtonGroupProps> = ({
  options,
  inline,
  value,
  optionValueName = 'value',
  optionLabelName = 'label',
  getLabelValue,
  formCheckProps,
  className,
  labelProps = {},
  onClick = () => {},
  onChange = () => {},
  ...props
}) => {
  return (
    <div {...props} className={cx({ 'd-flex': inline, className })}>
      {options.map(option => {
        return (
          <Form.Check
            key={option[optionValueName]}
            {...formCheckProps}
            onChange={() => onChange(option)}
            value={option[optionValueName]}
            type="radio"
            onClick={onClick}
            id={`radio_${option[optionLabelName]}-${option[optionValueName]}`}
            checked={value?.[optionValueName] === option[optionValueName]}
            label={
              <span {...labelProps}>
                {getLabelValue
                  ? getLabelValue(option)
                  : option[optionLabelName]}
              </span>
            }
          />
        );
      })}
    </div>
  );
};

export default FormRadioButtonGroup;
