export enum Stack9SortValue {
  asc = 1,
  desc = -1,
}

export interface IStack9SearchSortFormat {
  [property: string]: Stack9SortValue;
}

export interface IStack9SearchWhereFormat {
  [property: string]: any;
}

export default interface IStack9SearchBodyFormat {
  $where?: IStack9SearchWhereFormat;
  $select?: string[];
  $withRelated?: string[];
  $sort?: IStack9SearchSortFormat;
}
