import { useRouter } from 'next/router';
import React, { FormEvent, useMemo, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';

import cx from 'classnames';
import getMenu from 'utils/getMenu';

import HeadWithSocialMedia from 'components/HeadWithSocialMedia/HeadWithSocialMedia';
import LogoIcon from 'components/Icons/Logo/Logo';
import MenuStructure from 'components/MenuStructure/MenuStructure';
import { useApp } from 'contexts/appContext';

import MainLayout from '../../layouts/MainLayout';

import s from './Page404Content.module.scss';

const Page404Content: React.FC = () => {
  const router = useRouter();
  const { menu404Structure } = useApp();
  const [searchText, setSearchText] = useState<string>();
  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    router.push(`/search?query=${searchText}`);
  };

  const menu = useMemo(() => getMenu(menu404Structure), [menu404Structure]);

  return (
    <>
      <HeadWithSocialMedia
        title="Surf Life Saving Foundation - Page Not Found"
        description="Page not Found"
        thumbnailURL="/imgs/404background.png"
      />
      <MainLayout>
        <div
          className={cx(
            'd-flex pt-5 pb-10 justify-content-center align-items-center mb-n9',
            s.background,
          )}
        >
          <Container
            className={cx(
              'position-relative z-100 d-flex flex-column align-items-center',
              s.container,
            )}
          >
            <h1 className={cx('text-white d-flex text-center mb-4', s.h1)}>
              4<LogoIcon className={cx('mx-2 mx-md-3', s.logo)} />4
            </h1>
            <h2 className={cx('text-white text-center mb-4', s.h2)}>
              Unfortunately the page you were looking for has had its flags
              taken in.
            </h2>
            <p className={cx('text-white text-center mb-4', s.p)}>
              Try going back to the previous page. Alternatively, use the links
              or the search box below to find the information you are looking
              for.
            </p>

            <MenuStructure
              menuItemProps={{
                className: 'text-white text-decoration-underline px-3',
              }}
              className="d-flex flex-wrap justify-content-center p-0 mb-5 text-white list-style-type-none"
              menu={menu}
            />

            <Form className={s.form} onSubmit={handleSubmit}>
              <div className="d-flex w-100">
                <Form.Control
                  value={searchText}
                  onChange={({ target: { value } }) => setSearchText(value)}
                  placeholder="search"
                  className="rounded-r-none rounded-sm"
                />
                <Button
                  size="sm"
                  className="rounded-l-none rounded-sm px-3"
                  type="submit"
                >
                  <FiSearch size={20} color="white" />
                </Button>
              </div>
            </Form>
          </Container>
        </div>
      </MainLayout>
    </>
  );
};

export default Page404Content;
