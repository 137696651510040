import React from 'react';
import { Card, Accordion, Button } from 'react-bootstrap';
import { GoChevronDown } from 'react-icons/go';

import cx from 'classnames';

import FAQ from 'components/ContentRecord/components/FAQ/FAQ';

import FAQAccordionToggle from '../FAQAccordionToggle/FAQAccordionToggle';

import s from './FAQAccordionList.module.scss';

export interface FAQItem {
  id: number;
  question: string;
  answer: string;
  link?: string;
  link_label?: string;
  download_file_url?: string;
}
type FAQProps = {
  faqs?: FAQItem[];
};
const FAQAccordionList: React.FC<FAQProps> = ({ faqs }) => {
  return (
    <Accordion>
      {faqs &&
        faqs.map(faq => (
          <Card key={faq.id} className={cx('mb-4', s.card)}>
            <Card.Header className="d-flex flex-row justify-content-between align-items-center px-4">
              <Accordion.Toggle
                as={Button}
                className={cx(
                  'text-bold',
                  'middle-blue-color',
                  'text-left',
                  'pl-0',
                  s['accordian-toggle'],
                )}
                variant="link"
                eventKey={faq.id.toString()}
                data-content="faq_question"
              >
                {faq.question}
              </Accordion.Toggle>
              <FAQAccordionToggle eventKey={faq.id.toString()}>
                <GoChevronDown />
              </FAQAccordionToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={faq.id.toString()}>
              <Card.Body className="pl-4 pr-5 pb-3 pt-2 text-smaller">
                <FAQ {...faq} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
    </Accordion>
  );
};

export default FAQAccordionList;
