import React, { FunctionComponent } from 'react';
import { HiChevronUp } from 'react-icons/hi';
// @ts-ignore
import { Progress } from 'react-sweet-progress';

import cx from 'classnames';
import currencyFormat from 'utils/curencyFormat';

import s from './SLSFProgressBar.module.scss';

export enum SLSFProgressBarSizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}
type SLSFProgressBarProps = {
  amountToAchieve: number;
  amountAchieved: number;
  className?: string;
  size?: SLSFProgressBarSizes;
};
const SLSFProgressBar: FunctionComponent<SLSFProgressBarProps> = ({
  size,
  className,
  amountToAchieve,
  amountAchieved,
}) => {
  let percent = (amountAchieved / amountToAchieve) * 100;
  percent = percent > 100 ? 100 : percent;
  const offset = 10 - percent / 10;
  return (
    <div className={cx(s['progressbar-container'], className)}>
      <Progress
        className={cx(s['slsf-progressbar'], {
          [s[`slsf-progressbar-${size}`]]: size,
        })}
        percent={percent + offset}
      />
      <div className="d-flex justify-content-between mt-3 mb-4 mb-lg-0 position-relative">
        <h4 className="text-white text-bold">
          <span>{currencyFormat(amountAchieved)}</span>
        </h4>
        <h4 className="text-white text-bold">
          <HiChevronUp className={s.icon} size={20} />
          <span>{currencyFormat(amountToAchieve)}</span>
        </h4>
      </div>
    </div>
  );
};

export default SLSFProgressBar;
