import Link from 'next/link';
import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { ILink } from 'models/link';

interface IProps extends ButtonProps {
  link: ILink;
}
const LinkButton: React.FC<IProps> = ({ link, ...props }) => {
  return (
    <Link href={link.link || ''}>
      <Button {...props}>{link.title}</Button>
    </Link>
  );
};

export default LinkButton;
