import { Frequency } from 'models/frequency';
import { IPageBlock } from 'models/pageBlock';
import { IPageBlockContent } from 'models/pageBlockContent';

export const instanceOfPageBlock = (object: any): object is IPageBlock => {
  return 'donation_campaign' in object;
};

export const instanceOfPageBlockContent = (
  object: any,
): object is IPageBlockContent => {
  return 'hubspot_form' in object;
};

export const CUSTOM_AMOUNT_MESSAGE =
  'Your donation will help to save lives at the beach this year';

export interface IDonateNowForm {
  donationOption?: any;
  donationFund?: any;
  frequency: Frequency; // 'OnceOff' | 'Monthly'
  customAmount?: number | string;
  donationDay?: number; // 15 | 28;
}

export enum DonateNowFormSteps {
  selectDonation = 'select-donation',
  selectFrequency = 'select-frequency',
  selectDonationDate = 'select-donation-date',
  summary = 'summary',
}
