import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';

import cx from 'classnames';

import s from './FAQ.module.scss';

interface FAQProps {
  answer: string;
  link?: string;
  link_label?: string;
  download_file_url?: string;
  className?: string;
}

const FAQ: React.FC<FAQProps> = ({
  answer,
  link,
  link_label,
  download_file_url,
  className,
}) => {
  return (
    <div className={cx(s.faq, className)}>
      <div
        data-content="faq_answer"
        dangerouslySetInnerHTML={{ __html: answer }}
      />
      <Row>
        {!!link && (
          <Col md={6}>
            <Button className="w-100" variant="outline-primary" href={link}>
              {link_label}
            </Button>
          </Col>
        )}
        {!!download_file_url && (
          <Col md={6}>
            <Button className="w-100" href={download_file_url}>
              Download File
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default FAQ;
