import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import cx from 'classnames';

import SLSFSwitch from 'components/SLSFSwitch/SLSFSwitch';
import { IDonationOption } from 'models/donationOption';
import { Frequency } from 'models/frequency';

import s from './SimpleDonateForm.module.scss';

export interface ISimpleDonateForm {
  donationOption?: any;
  frequency: Frequency; // 'OnceOff' | 'Monthly'
  customAmount?: number | string;
  donationDay?: number; // 15 | 28;
}
type SimpleDonateFormProps = {
  onPurchase(
    value: ISimpleDonateForm,
    asAGuest: boolean,
    createNewAccountTabActive: boolean,
  ): void;
  className?: string;
  value: ISimpleDonateForm;
  onChange(wholeValue: any, key?: string, value?: any): void;
  donationOptions: IDonationOption[];
  loading?: boolean;
  btnDonateProps?: any;
};
const SimpleDonateForm: React.FC<SimpleDonateFormProps> = ({
  value: { donationOption, frequency, customAmount, donationDay },
  onChange,
  onPurchase,
  className,
  donationOptions,
  btnDonateProps,
}) => {
  function purchaseNow(asAGuest = false, createNewAccountTabActive = false) {
    onPurchase(
      {
        donationOption,
        frequency,
        customAmount: !customAmount ? undefined : customAmount,
        donationDay,
      },
      asAGuest,
      createNewAccountTabActive,
    );
  }

  const handleChange = (key: string, value: any) => {
    const wholeValue: ISimpleDonateForm = {
      donationOption: key === 'customAmount' ? undefined : donationOption,
      frequency,
      donationDay,
      customAmount: key === 'donationOption' ? '' : customAmount,
      [key]: value,
    };
    switch (key) {
      case 'frequency':
        if (value === Frequency.OnceOff) {
          wholeValue.donationDay = undefined;
        } else {
          wholeValue.donationDay = 28;
        }
        break;
      default:
    }
    onChange(wholeValue, key, value);
  };

  const isValid = donationOption || customAmount;

  return (
    <Form onSubmit={ev => ev.preventDefault()} className={className}>
      <div className="d-flex flex-wrap justify-content-center">
        {donationOptions.map(donOpt => (
          <Button
            key={donOpt.id}
            className="p-2 px-3 mx-2 mb-3 btn-semi-squared border-width-5"
            size="lg"
            variant={donOpt === donationOption ? 'primary' : 'outline-primary'}
            onClick={() => {
              handleChange('donationOption', donOpt);
            }}
          >
            ${parseInt(donOpt?.amount?.toString(), 10)}
          </Button>
        ))}
      </div>
      <Row noGutters className="m-0 px-xs-2 px-md-3">
        <Col lg={6} className="d-flex align-items-center">
          <span className="text-bold">Enter custom donation</span>
        </Col>
        <Col lg={6}>
          <div className="d-flex align-items-center">
            <h3 className="text-inherit my-0 mr-1">$</h3>
            <Form.Control
              required
              type="number"
              placeholder="Enter amount"
              value={customAmount}
              min={1}
              className="square-amount-input"
              onChange={({ target: { value } }) => {
                handleChange('customAmount', Math.abs(parseInt(value, 10)));
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-center align-items-center my-4">
        <h4 className={cx('text-right', 'pr-3', s['donate-label'])}>
          Monthly Donation
        </h4>
        <SLSFSwitch
          offColor="#FFF"
          onColor="#FFF"
          checked={frequency === Frequency.OnceOff}
          onChange={value =>
            handleChange(
              'frequency',
              value ? Frequency.OnceOff : Frequency.Monthly,
            )
          }
        />
        <h4 className={cx('text-left', 'pl-3', s['donate-label'])}>
          Once-off Donation
        </h4>
      </div>
      <Form.Row
        className={cx(
          'mb-3 mx-n1 justify-content-center',
          s['checkbox-group'],
          { [s.hide]: frequency === Frequency.OnceOff },
        )}
      >
        {[15, 28].map((value: number) => (
          <Form.Label
            key={value}
            className="d-flex align-items-center font-weight-normal text-inherit mx-3"
          >
            <Form.Check
              type="radio"
              value={value}
              className="mr-2"
              checked={donationDay === value}
              onChange={() => {
                handleChange('donationDay', value);
              }}
            />
            <h4 className="text-medium">{value}th of Month</h4>
          </Form.Label>
        ))}
      </Form.Row>
      <div className="d-flex justify-content-center">
        <Button
          {...btnDonateProps}
          disabled={!isValid}
          onClick={() => purchaseNow()}
        >
          Donate Now
        </Button>
      </div>
    </Form>
  );
};

export default SimpleDonateForm;
