import React, { HTMLAttributes } from 'react';
import { ButtonProps } from 'react-bootstrap';

import cx from 'classnames';

import LinkButton from 'components/LinkButton/LinkButton';
import { ILink } from 'models/link';

interface LinkButtonRowProps extends HTMLAttributes<HTMLElement> {
  links: ILink[];
  btnProps?: ButtonProps;
}
const defaultBtnProps = {
  className: 'mr-2 mb-2',
  variant: 'danger',
};
const LinkButtonRow: React.FC<LinkButtonRowProps> = ({
  links,
  btnProps = {},
  className = 'mb-n2 mt-3',
  ...props
}) => {
  const finalBtnProps = { ...defaultBtnProps, btnProps };
  return links?.length ? (
    <div className={cx(className, 'mb-n2')} {...props}>
      {links.map(link => (
        <LinkButton
          {...finalBtnProps}
          className={cx(finalBtnProps.className, 'mb-2')}
          key={link.id}
          link={link}
        />
      ))}
    </div>
  ) : (
    <></>
  );
};

export default LinkButtonRow;
