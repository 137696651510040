import React, { FunctionComponent, useContext, ReactNode } from 'react';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';

import cx from 'classnames';

import s from './FAQAccordionToggle.module.scss';

type FAQAccordianToggleProps = {
  children: ReactNode;
  eventKey: string;
  callback?: (eventKey: string) => void;
};
const FAQAccordionToggle: FunctionComponent<FAQAccordianToggleProps> = ({
  children,
  eventKey,
  callback,
}) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className={cx(s['accordian-toggle-arrow'], {
        [s.active]: isCurrentEventKey,
      })}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
};

export default FAQAccordionToggle;
