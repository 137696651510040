import React from 'react';
import { Col, Row } from 'react-bootstrap';

import cx from 'classnames';

import Card from 'components/Card/Card';

import s from './HorizontalRoundedCornerContent.module.scss';

type HorizontalRoundedCornerContentColProps = {
  imgURL?: string;
  className?: string;
  backdrop?: boolean;
  lg?: number;
  sm?: number;
  md?: number;
};

const HorizontalRoundedCornerContentCol: React.FC<
  HorizontalRoundedCornerContentColProps
> = ({ className, children, imgURL, backdrop, ...props }) => {
  return (
    <Col
      style={
        imgURL
          ? {
              backgroundImage: `url(${imgURL})`,
            }
          : undefined
      }
      lg={6}
      className={cx(
        'background-cover position-relative p-4 py-5 py-lg-6',
        className,
        s['horizontal-rounded-corner-content-col'],
        { [s['horizontal-rounded-corner-content-col-backdrop']]: backdrop },
      )}
      {...props}
    >
      <div className={s['horizontal-rounded-corner-content-col-top']}>
        {children}
      </div>
    </Col>
  );
};

const HorizontalRoundedCornerContent: React.FC<{
  loading?: boolean;
  rowProps?: any;
  className?: string;
}> & {
  Col: React.FC<HorizontalRoundedCornerContentColProps>;
} = ({ children, rowProps, className, ...props }) => {
  return (
    <Card
      className={`overflow-hidden ${s['horizontal-rounded-corner-content']} ${className}`}
      {...props}
    >
      <Row className="m-0" noGutters {...rowProps}>
        {children}
      </Row>
    </Card>
  );
};

HorizontalRoundedCornerContent.Col = HorizontalRoundedCornerContentCol;

export default HorizontalRoundedCornerContent;
