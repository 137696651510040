import React, { FunctionComponent } from 'react';
import Switch from 'react-switch';

import s from './SLSFSwitch.module.scss';

type ISLSFSwitch = {
  checked: boolean;
  onChange(value: boolean): void;
  offColor?: string;
  onColor?: string;
};
const SLSFSwitch: FunctionComponent<ISLSFSwitch> = props => {
  return (
    <Switch
      className={s['slsf-switch']}
      uncheckedIcon={false}
      checkedIcon={false}
      offColor="#EBEBEB"
      onColor="#EBEBEB"
      height={54}
      width={110}
      handleDiameter={44}
      {...props}
    />
  );
};

export default SLSFSwitch;
