import { useRouter } from 'next/router';
import React from 'react';
import { CardProps } from 'react-bootstrap';

import cx from 'classnames';

import Card from 'components/Card/Card';

import s from './VerticalTile.module.scss';

export interface VerticalTileProps extends CardProps {
  imageURL?: string;
  imageAlt?: string;
  className?: string;
  textOnTopOfImage?: string | JSX.Element;
  style?: any;
  slug?: string;
}

const VerticalTile: React.FC<VerticalTileProps> = ({
  imageURL,
  imageAlt,
  className,
  textOnTopOfImage,
  children,
  slug,
  ...props
}) => {
  const router = useRouter();

  const onImageClick = () => {
    router.push(`/article/${slug}`);
  };

  return (
    <Card
      className={cx(s['vertical-tile'], 'overflow-hidden', className)}
      {...props}
    >
      <Card.Body
        className={cx('p-4 d-flex flex-column', {
          'justify-content-between': imageURL,
          'justify-content-end': !imageURL,
        })}
      >
        {imageURL && (
          <div
            className={cx(s['vertical-tile-image-wrapper'], {
              [s['vertical-tile-image-wrapper-backdrop']]: textOnTopOfImage,
            })}
            role={slug ? 'button' : ''}
            onClick={() => {
              if (slug) onImageClick();
            }}
            aria-hidden
          >
            <img
              loading="lazy"
              className="w-100 h-100 pointer"
              src={imageURL}
              alt={imageAlt}
            />
            {textOnTopOfImage && (
              <div className={s['vertical-tile-image-wrapper-top']}>
                {textOnTopOfImage}
              </div>
            )}
          </div>
        )}
        {children}
      </Card.Body>
    </Card>
  );
};

export default VerticalTile;
