import React, { useContext, useState } from 'react';

import cx from 'classnames';

import HorizontalRoundedCornerContent from 'components/HorizontalRoundedCornerContent/HorizontalRoundedCornerContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import SimpleDonateForm, {
  ISimpleDonateForm,
} from 'components/SimpleDonateForm/SimpleDonateForm';
import { CartContext } from 'contexts/cartContext';
import { IColourSchema } from 'models/colourSchema';
import { IDonationCampaign } from 'models/donationCampaign';
import { Frequency } from 'models/frequency';

type DonationWidgetBlockProps = {
  donationCampaign?: IDonationCampaign;
  fluid?: boolean;
  colourSchema?: IColourSchema;
  isRegularDonation?: boolean;
  backdrop?: boolean;
  noPadding?: boolean;
  className?: string;
  campaignBackgroundImageURL?: string;
};
const DonationWidgetBlock: React.FC<DonationWidgetBlockProps> = ({
  colourSchema,
  fluid,
  campaignBackgroundImageURL,
  donationCampaign,
  isRegularDonation,
  backdrop,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [simpleDonateFormValue, setSimpleDonateFormValue] = useState<any>({
    donationDay: 28,
    frequency: Frequency.Monthly,
  });
  const {
    actions: { goToCheckoutPage },
  } = useContext(CartContext);
  if (!donationCampaign?.donation_options) {
    return <h1>There is no donationCampaign set</h1>;
  }

  const handleSimpleDonateChange = (value: ISimpleDonateForm) => {
    setSimpleDonateFormValue(value);
  };

  const handlePurchaseSimpleDonate = async (value: ISimpleDonateForm) => {
    setLoading(true);
    await goToCheckoutPage(
      {
        donation_campaign_id: donationCampaign.id,
        donation_option_id: value?.donationOption?.id,
        donation_day: value.donationDay,
        frequency: value.frequency,
        custom_unit_amount: value.customAmount
          ? parseInt(value.customAmount.toString(), 10)
          : undefined,
        quantity: 1,
      },
      false,
      false,
    );
  };

  const colourSchemaName = colourSchema?.name || 'secondary';

  const bgColor = `bg-${colourSchemaName}`;
  const { submitButtonColor, textColor } =
    colourSchemaName === 'secondary'
      ? {
          submitButtonColor: undefined,
          textColor: undefined,
        }
      : {
          submitButtonColor: 'danger',
          textColor: 'text-white',
        };

  const getLeftBlockColors = (
    name?: string,
  ): {
    leftBlockBgColor: string;
    leftBlockTextColor: string;
    leftBlockHeadingColor: string;
    headingColor: string;
  } => {
    const colors: any = {
      primary: {
        leftBlockBgColor: 'primary',
        leftBlockTextColor: 'white',
        leftBlockHeadingColor: 'secondary',
        headingColor: 'secondary',
      },
      secondary: {
        leftBlockBgColor: 'primary',
        leftBlockTextColor: 'white',
        leftBlockHeadingColor: 'secondary',
        headingColor: 'danger',
      },
    };

    return name && colors[name] ? colors[name] : colors.primary;
  };

  const {
    leftBlockBgColor,
    leftBlockTextColor,
    leftBlockHeadingColor,
    headingColor,
  } = getLeftBlockColors(colourSchemaName);

  return (
    <SectionWithContainer {...props} containerProps={{ fluid }}>
      <HorizontalRoundedCornerContent loading={loading}>
        <HorizontalRoundedCornerContent.Col
          className={`text-${leftBlockTextColor} bg-${leftBlockBgColor}`}
          backdrop={backdrop !== undefined ? backdrop : !isRegularDonation}
          imgURL={!isRegularDonation ? campaignBackgroundImageURL : undefined}
        >
          {!isRegularDonation ? (
            <div className="d-flex flex-grow-1 flex-column justify-content-center px-5 w-100">
              {donationCampaign?.heading_image && (
                <img
                  loading="lazy"
                  src={donationCampaign?.heading_image?.url}
                  alt={donationCampaign.heading_image_alt}
                />
              )}
            </div>
          ) : (
            <>
              <h2 className={`w-100 heading heading-${leftBlockHeadingColor}`}>
                Give monthly and become a Guardian of the Surf
              </h2>
              <p className="w-100">
                Our monthly supporters truly are Guardians of the Surf and
                critical to us. By becoming a Guardian of the Surf and donating
                monthly, we can plan for the future and ensure all our surf
                lifesavers have the crucial training as well as the right
                equipment they need.
              </p>
            </>
          )}
        </HorizontalRoundedCornerContent.Col>
        <HorizontalRoundedCornerContent.Col className={cx(bgColor, textColor)}>
          <h2
            className={`heading heading-center heading-${headingColor} text-center mx-auto w-100`}
          >
            Donate Now
          </h2>
          <SimpleDonateForm
            className="w-100"
            btnDonateProps={{
              variant: submitButtonColor,
            }}
            donationOptions={donationCampaign.donation_options}
            onChange={handleSimpleDonateChange}
            onPurchase={handlePurchaseSimpleDonate}
            value={simpleDonateFormValue}
          />
        </HorizontalRoundedCornerContent.Col>
      </HorizontalRoundedCornerContent>
    </SectionWithContainer>
  );
};

export default DonationWidgetBlock;
