import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import CircleAvatarOverflow from 'components/CircleAvatarOverflow/CircleAvatarOverflow';

type DonateTodayWithSurfLifeSaverAvatarProps = {
  onDonateToday(): void;
};
const DonateTodayWithSurfLifeSaverAvatar: React.FC<
  DonateTodayWithSurfLifeSaverAvatarProps
> = ({ onDonateToday }) => {
  return (
    <Row className="m-0">
      <Col
        className="flex-shrink-0 mb-2 d-flex flex-column align-items-center"
        xs={12}
        sm="auto"
      >
        <CircleAvatarOverflow size="lg" className="bg-white">
          <img loading="lazy" src="/imgs/avatar.png" alt="surf life saver" />
        </CircleAvatarOverflow>
      </Col>
      <Col className="d-flex flex-column align-items-center align-items-md-start">
        <h3 className="mb-4 text-center text-md-left">
          Keep your surf lifesavers on the beach.
        </h3>
        <Button variant="danger" onClick={onDonateToday}>
          Donate today
        </Button>
      </Col>
    </Row>
  );
};

export default DonateTodayWithSurfLifeSaverAvatar;
